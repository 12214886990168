.page-reminder-preview-modal {
  ion-content {
    --background: var(--ion-color-light);
  }

  ion-list {
    background-color: var(--ion-color-light);
  }

  ion-item {
    --background: var(--ion-color-light);
    --highlight-height: 0;
  }

  ion-note {
    font-size: 1rem;

    &.day-offset {
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 1rem;
    }

    &.notify-date {
      display: inline-flex;
      align-items: center;
      padding-top: 13px;
      padding-bottom: 13px;

      ion-toggle {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }

  ion-footer {
    background-color: var(--ion-color-light);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25%;

    // 移除 footer 預設的 border
    &::before {
      content: none;
    }

    ion-button.add-reminder {
      height: 48px;

      &::part(native) {
        padding-inline-start: 24px;
        padding-inline-end: 24px;
      }
    }
  }
}
