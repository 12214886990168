.page-settings {
  .header-md::after {
    display: none;
  }

  ion-header {
    background: var(--ion-color-light) !important;
  }

  ion-item-divider.header {
    background: var(--ion-color-light) !important;
    border: none !important;
    height: 56px;
  }

  ion-footer {
    display: none;
    ion-button {
      padding: 0;
      margin: 0;
      ion-label {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}

.plt-mobile:not(.plt-tablet) .page-settings {
  ion-footer {
    display: block;
  }
}
