.component-popup-menu {
  $size: 32px;

  .popup-menu ion-icon {
    text-align: center;
    color: var(--ion-color-primary);
    font-size: 2.5em;
  }

  .popup-menu-title {
    color: var(--ion-color-primary);
    display: block;
    text-align: center;
    width: 220px;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 36px;
    padding: 5px;
    margin: 0 auto;
    font-weight: bold;
    max-height: 45px;
    min-height: 45px;
    border-bottom: 1px solid var(--ion-color-primary);
  }

  .popup-menu-items {
    display: flex;
    width: 100%;
    padding: 15px;
    justify-content: space-between;
  }

  .popup-menu-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    background-image: -webkit-linear-gradient(
      rgba(68, 68, 68, 0.5) 0%,
      rgba(68, 68, 68, 0.25) 100%
    );
    background-image: linear-gradient(
      rgba(68, 68, 68, 0.5) 0%,
      rgba(68, 68, 68, 0.25) 100%
    );
  }

  .popup-menu-overlay.in {
    opacity: 1;
    visibility: visible;
  }

  .popup-menu-toggle {
    position: fixed;
    width: $size;
    height: $size;
    right: 8px;
    top: 12px;
    background: var(--ion-color-primary);
    z-index: 101;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    color: var(--ion-color-light);
    cursor: pointer;
  }

  .popup-menu ion-icon {
    position: absolute;
    width: $size;
    height: $size;
    top: 0px;
    left: 0px;
    line-height: $size;
    text-align: center;
    font-size: 24px;
    color: var(--ion-color-light);
    opacity: 1;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .popup-menu-toggle.out {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
  }

  .popup-menu-toggle.out:before {
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  .popup-menu-panel {
    position: fixed;
    width: 250px;
    height: auto;
    border-radius: none;
    top: 20px;
    right: 18px;
    margin-left: -100px;
    padding: 0px 5px;
    background-color: white;
    z-index: 102;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scale(0);
    transform: scale(0);
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .popup-menu-panel .popup-menu-item {
    margin: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .popup-menu-panel .popup-menu-item i {
    margin: auto 0px 5px 0px;
    padding: 0px;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: var(--ion-color-primary);

    cursor: pointer;
  }

  .popup-menu-panel .popup-menu-item span {
    padding: 0px;
    margin: 0px 0px auto 0px;
    color: var(--ion-color-primary);
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 11px;

    cursor: pointer;
  }

  .popup-menu-panel .popup-menu-item:active i {
    color: var(--ion-color-warning);
    -webkit-transition: all 0.15s;
    transition: all 0.15s;
  }

  .popup-menu-panel .popup-menu-item:active span {
    color: var(--ion-color-warning);
    -webkit-transition: all 0.15s;
    transition: all 0.15s;
  }

  .popup-menu-panel.in {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }

  .popup-menu-panel.in .popup-menu-item {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
  }

  .logo {
    width: 100%;
    margin-bottom: 5px;
    cursor: pointer;

    .suiqui {
      margin: 0 auto;
      height: $size;
      width: $size;
      border-radius: 50%;
      background: url('../assets/img/icon-suiqui-team-mini.png') no-repeat left
        top !important;
      background-size: $size $size !important;
    }

    .checklist {
      margin: 0 auto;
      height: $size;
      width: $size;
      border-radius: 50%;
      background: url('../assets/img/icon-checklist-mini.png') no-repeat left
        top !important;
      background-size: $size $size !important;
    }

    .dailyreport {
      margin: 0 auto;
      height: $size;
      width: $size;
      border-radius: 50%;
      background: url('../assets/img/icon-dailyreport-mini.png') no-repeat left
        top !important;
      background-size: $size $size !important;
    }

    .library {
      margin: 0 auto;
      height: $size;
      width: $size;
      border-radius: 50%;
      background: url('../assets/img/icon-library-mini.png') no-repeat left top !important;
      background-size: $size $size !important;
    }

    .support {
      margin: 0 auto;
      height: $size;
      width: $size;
      border-radius: 50%;
      background: url('../assets/img/icon-support-mini.png') no-repeat left top !important;
      background-size: $size $size !important;
    }

    .dashboard {
      margin: 0 auto;
      height: $size;
      width: $size;
      border-radius: 50%;
      background: url('../assets/img/icon-dashboard-mini.png') no-repeat left top !important;
      background-size: $size $size !important;
    }
  }
}
