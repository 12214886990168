.page-filter-form {
  .form-container {
    border: 2px solid var(--ion-color-light);
  }

  .form-container > .MuiGrid-root {
    padding: 15px 15px 0 15px;
  }

  .MuiGrid-root > .MuiGrid-root {
    padding: 10px 10px 0 10px;
  }

  h5 {
    color: var(--ion-color-primary);
  }

  p.Mui-required,
  label.Mui-required {
    color: var(--ion-color-primary);
    font-weight: bold;
  }

  label.Mui-required .MuiFormLabel-asterisk {
    color: var(--ion-color-danger) !important;
  }

  label.Mui-required ~ .MuiFormGroup-root checkbox {
    color: var(--ion-color-danger) !important;
  }

  ion-item-divider {
    border: none;
    background: transparent;

    font-size: 16px;
  }
}
