
.page-team-switch-modal {
  ion-item {
    --background: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  ion-item:hover {
    background-color: rgba(239, 160, 11, 0.5) !important;
    color: #000000 !important;
  }

  ion-item.active {
    opacity: unset;
    background-color: rgba(102, 157, 49, 0.5) !important;
    color: #FFFFFF !important;
  }
}
