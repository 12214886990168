.page-template {
  @import '../../theme/logo';

  ion-content.bg {
    --background: var(--ion-color-light);
  }

  ion-list {
    margin: 0;
    padding: 1px 0 1px 0;
    &.bg {
      background: var(--ion-color-light);
    }
  }

  .input-disabled div[name$='_schema'] > * {
    pointer-events: none !important;
  }

  div[name$='_schema'] {
    padding: 10px 10px 30px 10px !important;
  }

  .input-disabled div[name$='_schema'] {
    border: 2px dashed rgba(var(--ion-color-secondary-rgb), 1) !important;
    margin-top: -2px;

    cursor: not-allowed !important;
  }

  ion-list-header.advanced {
    margin-bottom: 0;
    padding-right: 4px;
    h3 {
      font-weight: bold;
    }
  }

  div.tips {
    color: var(--ion-color-danger);
    font-weight: bold;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: right;
  }
}

@media only screen and (min-height: 0px) and (min-width: 0px) {
  .modal-fullscreen .modal-wrapper {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
  }
}

.page-template {
  .save-button {
    display: flex;
    align-items: center;

    ion-icon {
      margin-right: 7px;
    }
  }
}
