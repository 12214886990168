@import '../../theme/logo';

ion-menu.component-menu {
  ion-content {
    --background: var(--ion-color-light);
  }

  ion-item.menu-item {
    --background: var(--ion-color-light);
    --border-radius: 0rem 1rem 1rem 0rem;
    --border-width: 0px 0px 0px 5px;
    --border-color: transparent;
    --padding-start: 30px;
  }

  .header-md::after {
    display: none;
  }

  ion-chip {
    text-transform: none;
  }

  .user-box {
    margin: 0;
    padding: 0;
    border-bottom: 2px solid rgba(var(--ion-color-light-rgb), 1);
  }

  .team-name {
    width: 100%;
    font-size: 15px;
  }

  .user-name {
    font-size: 13px;
    font-weight: bold;
  }

  .line-box {
    .line-svg {
      display: block;
      fill: white;
      width: 25px;
      height: 25px;
      background: var(--ion-color-medium);

      &.enabled {
        background: var(--ion-color-success);
      }
    }

    button.verification-code {
      cursor: pointer;
      font-weight: bold !important;
      color: var(--ion-color-success) !important;
      background: transparent !important;
    }

    a {
      pointer-events: auto;
      display: inline-block;
      vertical-align: middle;
    }

    .tip {
      color: var(--ion-color-medium);
    }
  }
}
