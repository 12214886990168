.page-file-remove-modal {
  ion-list {
    padding: 0;
  }

  ion-checkbox {
    margin-inline-start: 0;
    margin-inline-end: 16px;
  }

  ion-item ion-note {
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  ion-item-divider {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;

    ion-label {
      height: 20px;
    }
  }
}
