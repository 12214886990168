.page-list-template {
  @import '../theme/logo';

  ion-content.bg {
    --background: var(--ion-color-light);
  }

  .header-md::after {
    display: none;
  }

  ion-list {
    margin: 0;
    padding: 1px 0 1px 0;
    background: transparent;
  }

  ion-card {
    box-shadow: none !important;
    border: none !important;

    ion-card-content {
      padding: 0 5px;
      display: block;
    }
  }
}
