.page-quick-add-account-modal {
  .centered {
    width: 100%;
  }

  .qr-code-team-name {
    display: block;
    margin-bottom: 12px;
    font-size: 1.3rem;

    p {
      font-size: 1rem;
    }
  }

  .footer-buttons {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    gap: 36px;
  }
}
