@import '../../theme/utils/mixin';

.page-ticket {
  @import '../../theme/logo';

  ion-content.bg {
    --background: var(--ion-color-light);
  }

  ion-list {
    margin: 0;
    padding: 1px 0 1px 0;
    &.bg {
      background: var(--ion-color-light);
    }
  }

  ion-list-header.subject {
    margin: 0;
    padding: 0;
  }

  ion-card {
    box-shadow: none !important;
    border: 1px solid rgba(var(--ion-color-medium-rgb), 0.15);

    ion-card-content {
      padding: 0 5px;
      display: block;
    }
  }

  .ticket.id {
    font-weight: bold;
  }

  .status-date-info {
    display: flex;
    align-items: center;
    margin: 10px 0 0 0 !important;
    padding: 0 !important;
    padding-top: 3px !important;
    font-size: 12px;
    font-weight: 700;
  }

  ion-text {
    padding-right: 7px;
  }

  .ticket-toolbar {
    --padding-top: 0px;
  }

  .ticket-status {
    font-size: 14px;
    padding: 7px 11px;
    margin-right: 5px;
    border-radius: 0.8rem;
  }

  .ticket-status-done {
    background-color: rgba(var(--ion-color-success-rgb), 0.2);
    color: var(--ion-color-success);
  }

  .ticket-status-in-progress {
    background-color: rgba(var(--ion-color-secondary-rgb), 0.2);
    color: var(--ion-color-secondary);
  }

  .ticket-status-observing {
    background-color: rgba(var(--ion-color-warning-rgb), 0.2);
    color: var(--ion-color-warning-dark);
  }

  .ticket-status-postponed {
    background-color: rgba(var(--ion-color-danger-rgb), 0.2);
    color: var(--ion-color-danger);
  }

  .ticket-status-preparing {
    background-color: rgba(#623DB1, 0.2);
    color: #623DB1; 
  }

  .ticket-status-waiting {
    background-color: rgba(#5AA1EE, 0.2);
    color: #5AA1EE;
  }

  .observation-start-date {
    color: var(--ion-color-warning-dark);
  }
}

#ticket-id-popover {
  --width: 280px !important;
}

.plt-mobile .page-ticket {
  @include respond-to-landscape {
    ion-footer {
      display: none;
    }
  }
}

@media only screen and (min-height: 0px) and (min-width: 0px) {
  .modal-fullscreen .modal-wrapper {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
  }
}

.pl-5 {
  padding-left: 5px;
}

.hidden {
  visibility: hidden;
}
