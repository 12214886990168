.signature-fields {
  padding: 20px;
  padding-bottom: 40px;

  .title {
    margin: 0;
    padding-bottom: 16px;
    color: var(--ion-color-primary);
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
  }

  .signatures {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    ion-button {
      height: 36px;
    }
  }
}
