@import '../theme/utils/mixin';

.page-file-viewer-modal {
  $top-height: 87px;
  $font-border-shadow: var(--ion-color-medium);

  iframe {
    border-width: none !important;
  }

  .swiper-zoom-container {
    min-height: calc(100vh - #{$top-height});
  }

  ion-content {
    --background: rgba(var(--ion-color-dark-rgb), 1);
    --offset-top: 0;
    --offset-top: 0;
  }

  ion-slide {
    display: flex;
    flex-wrap: wrap;
  }

  .filename,
  .user {
    color: var(--ion-color-light);
    font-size: 12px;
    font-weight: bold;
    text-shadow: -1px 0 $font-border-shadow, 0 1px $font-border-shadow,
      1px 0 $font-border-shadow, 0 -1px $font-border-shadow;
  }

  .audio-container {
    min-height: calc(100vh - #{$top-height}) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pdf-container {
    embed {
      width: 100% !important;
      min-height: calc(100vh - #{$top-height}) !important;
    }
  }

  .video-container {
    min-height: calc(100vh - #{$top-height}) !important;
    display: flex;
    align-items: center;
    justify-content: center;

    video::-webkit-media-controls-panel-container {
      display: none !important;
    }

    .video-js.vjs-fluid,
    .video-js.vjs-16-9,
    .video-js.vjs-4-3,
    video.video-js,
    video.vjs-tech {
      max-height: calc(100vh - #{$top-height}) !important;
      position: relative !important;
      width: 100%;
      height: auto !important;
      max-width: 100% !important;
      padding-top: 0 !important;
      line-height: 0;
    }

    .vjs-control-bar {
      line-height: 1;
    }
  }
}

@media only screen and (min-height: 0px) and (min-width: 0px) {
  $top-height: 92px;

  .modal-fullscreen {
    .modal-wrapper {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;

      .pdf-container {
        embed {
          width: 100% !important;
          min-height: calc(100vh - #{$top-height}) !important;
        }
      }

      .video-container {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      @include respond-to('max-width', '768px') {
        @include respond-to-landscape {
          ion-header {
            display: none;
          }
        }
      }
    }
  }
}
