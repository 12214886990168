.component-ticket-comment {
  ion-card-header {
    background: var(--ion-color-light);
  }

  ion-list {
    border-top: 4px dashed var(--ion-color-light);

    ion-item {
      font-size: 13px;
      font-weight: bold;
      color: dashed var(--ion-color-medium);
    }

    ion-button.download {
      font-size: 13px;
    }

    ion-note {
      margin: 0;
    }

    ion-thumbnail {
      width: 48px;
      height: 48px;
    }

    ion-thumbnail.photo {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding: 0;
    }

    ion-item-divider.download {
      margin-bottom: 5px;
    }
  }
}
