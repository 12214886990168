.edit-field-modal {
  ion-list {
    padding: 0px 20px;
  }

  h5 {
    font-size: 18px;
    margin-bottom: 25px;
  }

  p.Mui-required,
  label.Mui-required {
    color: var(--ion-color-primary);
    font-weight: bold;
  }

  .field-name-input {
    display: flex;

    .MuiFormControl-root {
      flex: 3;
      padding-right: 25px;
    }
  }

  .add-option-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 35px;
  }

  .input-field {
    margin-bottom: 20px;
  }

  .no-option-hint {
    margin-left: 12px;
    color: var(--ion-color-danger);
    font-size: 14px;
  }

  .option-input-field {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .delete-option-btn {
      margin-left: 5px;
    }
  }

  .options-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
