.page-export-templates-modal {
  .date-picker-container {
    margin: 16px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    > span {
      font-size: 20px;
      margin: 0 16px;
    }
  }

  .checkbox-label {
    width: 100%;
  }
}
