.no-reminder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75%; // 100% 減去 footer 的 25% 高度
  color: var(--ion-color-medium);

  ion-icon {
    font-size: 5rem;
    margin-bottom: 16px;
  }

  ion-label h2 {
    font-size: 1.25rem;
    font-weight: 500;
  }
}
