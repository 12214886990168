html.wf-active.plt-desktop {
  --ion-font-family: 'Noto Sans TC', sans-serif;
}

:root {
  font-family: var(--ion-font-family) !important;
  font-display: optional;

  ion-button {
    text-transform: none;
  }

  ion-button.cancel {
    color: var(--ion-color-medium);
  }

  ion-button.ok {
    color: var(--ion-color-primary);
    font-weight: bold;
  }

  ion-button.separator {
    span {
      font-size: 20px;
      font-weight: bold;
      font-family: monospace;
      color: rgba(var(--ion-color-light-rgb), 0.42);
    }
  }

  .header-md::after {
    display: none;
  }

  ion-list-header {
    margin-bottom: 10px;

    ion-label {
      h2 {
        font-size: 22px;
      }

      h3 {
        font-size: 16px;
      }
    }
  }
}

ion-split-pane {
  --border: none !important;
  --side-max-width: 19rem;
}

.title-md {
  font-size: 18px !important;
}

.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.update-available {
  --background: var(--ion-color-primary-shade) !important;
}

.plt-ios ion-content {
  pointer-events: auto !important; /* to fix scroll lock on ios */
}

div.ion-text-center.centered ion-spinner {
  transform: scale(2);
}

.ion-padding-end {
  padding-right: 45px;
}

.bg {
  --background: var(--ion-color-light);
}

/* 
 * fix bug since chrome v114 popover api by workaround-way
 * Thanks from Edison providing ref. link: https://github.com/ionic-team/ionic-framework/issues/27599#issuecomment-1579449149
 *
 * 另一個解決方式 (
  ref. links:
  1. https://stackoverflow.com/a/76381282 , 
  2. https://stackoverflow.com/a/76382473,  
  3. https://github.com/ionic-team/ionic-framework/pull/26672, 
  4. https://github.com/ionic-team/ionic-framework/issues/26643 
  ): 
 * ion-popover [popover]:not(:popover-open):not(dialog[open]) {
 *   display: contents;
   } 
 * 若 升級到 ionic/react v6.5.2+ 以後的版本，則此段可以移除。
 */
[popover].popover-viewport {
  display: initial;
  position: initial;
  margin: initial;
  border: initial;
  background: initial;
  padding: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}