.page-public-ticket {
  @import '../../theme/logo';

  ion-content.bg {
    --background: var(--ion-color-light);
  }

  ion-footer {
    ion-toolbar {
      ion-button.toggle {
        height: 42px;
        --height: 42px;
        --min-height: 42px;
      }
    }
  }

  ion-list {
    margin: 0;
    padding: 1px 0 1px 0;
    &.bg {
      background: var(--ion-color-light);
    }
  }

  ion-list-header.subject {
    margin: 0;
    padding: 0;
  }

  ion-card {
    box-shadow: none !important;
    border: 1px solid rgba(var(--ion-color-medium-rgb), 0.15);

    ion-card-content {
      padding: 0 5px;
      display: block;
    }
  }

  .ticket.id {
    font-weight: bold;
  }

  .page-preview-form {
    .MuiAutocomplete-inputRoot input[readonly] {
      -webkit-touch-callout: none !important;
      user-select: none !important;
      cursor: default !important;
    }

    .form-disabled div[role='combobox'] {
      pointer-events: none !important;
      input {
        cursor: default !important;
      }
    }

    .form-disabled {
      cursor: default !important;
    }
  }

  @include respond-to-landscape {
    ion-footer {
      display: none;
    }
  }

  .ticket-status {
    font-size: 14px;
    padding: 7px 11px;
    margin-right: 5px;
    border-radius: 0.8rem;
  }

  .ticket-status-in-progress {
    background-color: rgba(var(--ion-color-secondary-rgb), 0.2);
    color: var(--ion-color-secondary);
  }

  .ticket-status-observing {
    background-color: rgba(var(--ion-color-warning-rgb), 0.2);
    color: var(--ion-color-warning-dark);
  }

  .ticket-status-postponed {
    background-color: rgba(var(--ion-color-danger-rgb), 0.2);
    color: var(--ion-color-danger);
  }

  .ticket-status-preparing {
    background-color: rgba(#623DB1, 0.2);
    color: #623DB1; 
  }

  .ticket-status-waiting {
    background-color: rgba(#5AA1EE, 0.2);
    color: #5AA1EE;
  }
}

@media only screen and (min-height: 0px) and (min-width: 0px) {
  .modal-fullscreen .modal-wrapper {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
  }
}

.split-pane {
  &.workaround {
    .SplitPane {
      z-index: 32767 !important;
      background: rgba(255, 255, 255, 1);
    }
  }
  .Resizer {
    background: rgba(var(--ion-color-medium-rgb), 0.1);
    opacity: 1;
    z-index: 1;
    box-sizing: border-box;
    background-clip: padding-box;
  }

  .Resizer:hover {
    background: rgba(var(--ion-color-dark-rgb), 0.1);
  }

  .Resizer.horizontal {
    height: 10px;
    min-height: 10px;
    margin: 0;
    cursor: row-resize;
    width: 100%;

    border: 1px solid rgba(var(--ion-color-light-rgb), 0.3);
    border-left: none !important;
    border-right: none !important;
  }

  .Resizer.horizontal:hover {
  }

  .Resizer.vertical {
    width: 10px;
    min-width: 10px;
    margin: 0;
    cursor: col-resize;

    border: 1px solid rgba(var(--ion-color-light-rgb), 0.3);
    border-top: none !important;
    border-bottom: none !important;
  }

  .Resizer.vertical:hover {
  }

  .Resizer.disabled {
    cursor: not-allowed;
  }

  .Resizer.disabled:hover {
    border-color: transparent;
  }
}
