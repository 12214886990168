@import '../theme/utils/mixin';

.page-photo-viewer-modal {
  $top-height: 56px;
  $font-border-shadow: var(--ion-color-medium-rgb);

  .swiper-zoom-container {
    min-height: calc(100vh - #{$top-height});
  }

  .file-icon-container {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - #{$top-height});
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file-icon-container i,
  .file-icon-container ion-icon {
    width: 200px;
    height: 200px;
    vertical-align: middle;
  }

  ion-slides {
    height: initial;
  }

  ion-slide {
    overflow-y: auto;
    display: block;
  }

  .scroll-content {
    overflow: hidden;
  }

  ion-content {
    --background: rgba(var(--ion-color-dark-rgb), 1);
  }

  .next {
    --background: rgba(var(--ion-color-dark-rgb), 0.42);
  }

  .prev {
    --background: rgba(var(--ion-color-dark-rgb), 0.42);
  }

  ion-badge.count {
    position: absolute;
    height: auto;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 24px;
    font-family: monospace;
    font-weight: bold;
  }

  ion-chip.memo {
    pointer-events: auto;
    cursor: text;
    user-select: text;
    position: absolute;
    height: auto;
    top: 0;
    text-shadow: -1px 0 $font-border-shadow, 0 1px $font-border-shadow,
      1px 0 $font-border-shadow, 0 -1px $font-border-shadow;
    background: rgba(var(--ion-color-dark-rgb), 0.5);
    font-weight: bold;

    white-space: pre-wrap;
  }

  ion-slide {
    display: flex;
    flex-wrap: wrap;
  }

  .infomation {
    position: absolute;
    bottom: 0;
    .filename,
    .user {
      pointer-events: auto;
      cursor: text;
      user-select: text;
      color: var(--ion-color-light);
      font-size: 12px;
      font-weight: bold;
      background: rgba(var(--ion-color-dark-rgb), 0.75);
    }
  }
}

.plt-mobile .page-photo-viewer-modal {
  $top-height: 128px;
  $top-height-standalone: 74px;

  .swiper-zoom-container {
    min-height: calc(100vh - #{$top-height}) !important;
    @media all and (display-mode: standalone) {
      min-height: calc(100vh - #{$top-height-standalone}) !important;
    }
  }

  .file-icon-container {
    min-height: calc(100vh - #{$top-height}) !important;
    @media all and (display-mode: standalone) {
      min-height: calc(100vh - #{$top-height-standalone}) !important;
    }
  }

  .infomation {
    @media all and (display-mode: standalone) {
      bottom: 0 !important;
    }
  }

  @include respond-to-landscape {
    .swiper-zoom-container {
      min-height: 100vh !important;
    }

    .file-icon-container {
      min-height: 100vh !important;
    }

    .infomation {
      bottom: 0 !important;
    }
  }
}

@media only screen and (min-height: 0px) and (min-width: 0px) {
  .modal-fullscreen {
    .modal-wrapper {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
    }
  }
}
