.page-forget-password-modal {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
  }

  .email-field {
    width: 80%;
    margin-bottom: 16px;
  }
}
