.reminder-preview-component {
  ion-note {
    font-size: 1rem;

    &.day-offset {
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 1rem;
    }

    &.notify-date {
      display: inline-flex;
      align-items: center;
      padding-top: 13px;
      padding-bottom: 13px;

      ion-toggle {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }

}
