.edit-mode-schema-field {
  --width: 100%;
  font-size: 16px;
  padding: 8px 20px;
  border: 1px solid rgba(var(--ion-color-medium-rgb), 0.15);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 2px 0px;
  border-radius: 6px;
  margin-top: 0;
  margin-bottom: 20px;

  .toolbar {
    width: 100%;
    color: var(--ion-color-primary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    margin-bottom: 6px;

    .signature-name {
      font-size: 18px;
      color: var(--ion-color-dark);
    }
  }

  .property {
    &:not(&:last-child) {
      margin-bottom: 16px;
    }

    &-key {
      color: var(--ion-color-medium);
      font-size: 14px;
    }

    &-value {
      margin-top: 2px;
      color: black;

      .icon {
        margin-right: 6px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  p {
    margin: 0;
  }
}
