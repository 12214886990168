.component-template-list-card {
  .dashboard-info {
    margin-top: 5px !important;
    font-weight: 700;
    ion-chip {
      padding: 7px 8px !important;
      height: 20px;
      font-size: 12px;
    }
  }
}
