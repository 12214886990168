@import '../theme/utils/mixin';

.page-upload-modal {
  .dropzone {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  @include respond-to('max-width', '540px') {
    ion-button.logo {
      background-position: left !important;
      width: 20px !important;
      margin-left: 0 !important;
    }
  }

  ion-reorder[slot='start'] {
    margin-inline-end: 25px;
  }

  ion-checkbox {
    margin-inline-end: 10px;
  }

  ion-list {
    background: transparent;
    padding: 10px !important;
  }

  ion-badge.ext {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    text-transform: uppercase;
    background: rgba(var(--ion-color-dark-rgb), 0.3);
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  ion-content.bg {
    --background: var(--ion-color-light);
  }

  ion-item div.icon-status {
    position: absolute;
    right: 0px;
    bottom: -2px;
  }

  .upload-submit {
    height: 48px;
  }

  ion-text.upload-placeholder {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    ion-icon {
      zoom: 10;
      color: rgba(var(--ion-color-medium-rgb), 0.2);
    }

    > ion-text {
      position: absolute;
      font-size: 30px;
      font-weight: bold;
      color: rgba(var(--ion-color-medium-rgb), 1);
      text-align: center;
      user-select: none;
    }
  }

  ion-item.compact.upload-folder {
    ion-icon {
      font-size: 18px;
      width: 18px;
      height: 18px;
      padding: 0;
      margin: 0;
      margin-right: 10px;
    }
  }

  ion-item.compact.upload-folder,
  ion-item.compact.upload-item {
    --min-height: 42px;
    --max-height: 42px;
    height: 42px;
    padding: 0;
    font-size: 12px;

    ion-avatar {
      font-size: 18px;
      width: 18px;
      height: 18px;
      padding: 0;
      margin: 0;
      margin-right: 10px;
    }

    .memo {
      font-size: 12px;
    }

    ion-chip {
      font-size: 12px;
    }

    ion-label {
      margin: 0 !important;
      padding: 0 !important;
    }

    ion-badge {
      font-size: 12px;
    }

    ion-note {
      padding: 0;
      margin: 0;
      margin-inline-start: 0;
      margin-inline-end: 18px;
    }

    ion-buttons {
      padding: 0;
      margin: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      position: absolute;
      right: 0;
    }

    ion-button {
      ion-icon {
        font-size: 15px;
      }
      ion-label {
        font-size: 12px;
      }
    }
  }
}
