.page-reminder-edit-modal {
  ion-content {
    --background: var(--ion-color-light);
  }

  ion-list {
    background-color: var(--ion-color-light);
  }

  .notification-bell {
    position: relative;

    ion-item {
      --background: var(--ion-color-light);
      --highlight-height: 0;
    }

    // 移除 input number 的 arrow buttons
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    // 讓移除按鈕顯示在 item-group 的右邊
    .remove-bell-btn {
      position: absolute;
      top: 30%;
      right: -16px;
    }
  }

  ion-footer {
    background-color: var(--ion-color-light);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25%;

    // 移除 footer 預設的 border
    &::before {
      content: none;
    }

    ion-button.add-reminder {
      height: 48px;

      &::part(native) {
        padding-inline-start: 24px;
        padding-inline-end: 24px;
      }
    }
  }
}
