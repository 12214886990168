.component-dashboard {
  --ion-grid-columns: 18;

  ion-card {
    margin: 10px 5px;

    ion-card-header {
      text-align: center;

      span {
        font-size: xx-large;
        font-weight: bold;
        color: rgba(var(--ion-color-secondary-rgb), 0.8);
      }
    }

    ion-card-title {
      text-align: center;
      color: rgba(var(--ion-color-medium-rgb), 1);
    }
  }
}
