/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #142850;
  --ion-color-primary-rgb: 20,40,80;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #122346;
  --ion-color-primary-tint: #2c3e62;

  --ion-color-secondary: #2176FF;
  --ion-color-secondary-rgb: 33,118,255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #1d68e0;
  --ion-color-secondary-tint: #3784ff;

  --ion-color-tertiary: #119DA4;
  --ion-color-tertiary-rgb: 17,157,164;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #0f8a90;
  --ion-color-tertiary-tint: #29a7ad;

  --ion-color-success: #009B72;
  --ion-color-success-rgb: 0,155,114;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #008864;
  --ion-color-success-tint: #1aa580;

  --ion-color-warning: #FAC748;
  --ion-color-warning-rgb: 250,199,72;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #dcaf3f;
  --ion-color-warning-tint: #fbcd5a;

  --ion-color-danger: #ED254e;
  --ion-color-danger-rgb: 237,37,78;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d12145;
  --ion-color-danger-tint: #ef3b60;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
