.page-template-form {
  background-color: white;

  .form-container > .MuiGrid-root {
    padding: 15px 15px 0 15px;
  }

  // 客戶選單 & 內部選單增加 padding
  .SubForm-grid > .MuiGrid-root {
    padding: 10px 10px 0 10px;
  }

  // + 維修人員 按鈕的 container
  .DynamicArray-header {
    // 新增 FieldArray item 後，不顯示新增 FieldArray item 的按鈕
    // Notice: 因為設定了 maxItem = 1 所以在新增後按鈕會被 disable
    &:has(button.Mui-disabled) {
      display: none;
    }

    // 按鈕本身
    button {
      width: 100%;
      // 隱藏預設的文字內容 (ADD)
      // 無法透過 props 來設定，所以透過 css 來隱藏
      color: transparent;

      // startIcon (icon 以及自訂的按鈕文字)
      > span {
        // 已隱藏預設的文字內容導致 span 位置偏移，所以透過 css 置中
        position: absolute;
        left: 50%;
        translate: -50%;
        margin: 0;
        color: var(--ion-color-primary);

        // 調整 icon (+號) 的位置
        ion-icon {
          position: relative;
          top: 1.5px;
          margin-right: 5px;
        }
      }
    }
  }

  // 維修人員 2 的姓名、電話欄位
  .DynamicArray-formControl > .MuiGrid-container {
    position: relative;

    // 調整維修人員 2 電話的 padding-top，使其與維修人員 2 姓名的 padding-top 一致
    .MuiGrid-container > .MuiGrid-item:not(:first-child) {
      padding-top: 16px;
    }

    // FieldArray 在沒有資料時顯示的提示文字，不需要在畫面上
    .MuiGrid-item:has(p) {
      display: none;
    }
  }

  // 移除維修人員 2 電話欄位下的 margin
  .DynamicArray-fieldArrayGroup {
    margin-bottom: 0 !important;
  }

  h5 {
    margin-bottom: 14px;
    color: var(--ion-color-primary);
    font-size: 24px;
  }

  p.Mui-required,
  label.Mui-required {
    color: var(--ion-color-primary);
    font-weight: bold;
  }

  ion-item-divider {
    border: none;
  }

  ion-toolbar.edit-button {
    --min-height: auto;
  }

  br {
    display: none !important;
  }

  .form-disabled > * {
    pointer-events: none;
  }

  .form-disabled div[role='combobox'] {
    pointer-events: auto !important;
  }

  .schema {
    margin-top: 40px;

    &:last-child {
      padding-bottom: 40px;
    }

    .schema-title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 0 10px;

      h5 {
        color: var(--ion-color-primary);
        font-size: 24px;
      }
    }

    .add-schema-field-btn {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
