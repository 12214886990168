.signature-modal {
  ion-list {
    padding: 0 20px 20px 20px;
  }

  h5 {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .field-name-input {
    display: flex;

    .MuiFormControl-root {
      flex: 3;
      padding-right: 25px;
    }
  }

  .signature {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 24px;

    &-canvas {
      border-radius: 10px;
      background-color: white;

      &-disabled {
        pointer-events: none;
      }
    }

    .toolbar {
      margin-bottom: 12px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
}
