.edit-signature-modal {
  ion-list {
    padding: 0 20px 20px 20px;
  }

  h5 {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .field-name-input {
    display: flex;

    .MuiFormControl-root {
      flex: 3;
      padding-right: 25px;
    }
  }
}
