.customer-photo-fields {
  padding: 20px;
  padding-bottom: 40px;

  .title {
    margin: 0;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5 {
      color: var(--ion-color-primary);
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.334;
    }
  }
}
