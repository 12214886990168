@import '../../theme/utils/mixin';

.page-ticket {
  @import '../../theme/logo';

  ion-content.bg {
    --background: var(--ion-color-light);
  }

  ion-list {
    margin: 0;
    padding: 1px 0 1px 0;
    &.bg {
      background: var(--ion-color-light);
    }
  }

  ion-list-header.subject {
    margin: 0;
    padding: 0;
  }

  ion-card {
    box-shadow: none !important;
    border: 1px solid rgba(var(--ion-color-medium-rgb), 0.15);

    ion-card-content {
      padding: 0 5px;
      display: block;
    }
  }

  .ticket.id {
    font-weight: bold;
  }

  .date-info {
    margin: 0 !important;
    padding: 0 !important;
    padding-top: 5px !important;
    ion-badge {
      font-size: 12px !important;
    }
  }
}

.plt-mobile .page-ticket {
  @include respond-to-landscape {
    ion-footer {
      display: none;
    }
  }
}

@media only screen and (min-height: 0px) and (min-width: 0px) {
  .modal-fullscreen .modal-wrapper {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
  }
}
