.page-print-ticket-modal {
  section {
    margin: 16px;
    margin-bottom: 24px;
  }

  .checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .checkbox-label {
    width: 100%;
  }

  .print-btn-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 24px;
  }
}
