.permission-chip {
  scale: 0.9;
  height: 24px;
  border-radius: 5px;
  padding: 0 8px;

  ion-icon {
    scale: 0.75;
    margin-right: 2px;
  }
}
