.page-preview-form {
  .form-container {
    border: 2px solid var(--ion-color-secondary);
  }

  .form-container > .MuiGrid-root {
    padding: 15px 15px 0 15px;
  }

  // 客戶選單 & 內部選單增加 padding
  .SubForm-grid > .MuiGrid-root {
    padding: 10px 10px 0 10px;
  }

  // + 維修人員 按鈕的 container
  .DynamicArray-header {
    // 新增 FieldArray item 後，不顯示新增 FieldArray item 的按鈕
    // Notice: 因為設定了 maxItem = 1 所以在新增後按鈕會被 disable
    &:has(button.Mui-disabled) {
      display: none;
    }

    // 按鈕本身
    button {
      width: 100%;
      // 隱藏預設的文字內容 (ADD)
      // 無法透過 props 來設定，所以透過 css 來隱藏
      color: transparent;

      // startIcon (icon 以及自訂的按鈕文字)
      > span {
        // 已隱藏預設的文字內容導致 span 位置偏移，所以透過 css 置中
        position: absolute;
        left: 50%;
        translate: -50%;
        margin: 0;
        color: var(--ion-color-primary);

        // 調整 icon (+號) 的位置
        ion-icon {
          position: relative;
          top: 1.5px;
          margin-right: 5px;
        }
      }
    }
  }

  // 維修人員 2 的姓名、電話mail 欄位
  .DynamicArray-formControl > .MuiGrid-container {
    position: relative;

    // 調整維修人員 2 電話的 padding-top，使其與維修人員 2 姓名的 padding-top 一致
    .MuiGrid-container > .MuiGrid-item:not(:first-child) {
      padding-top: 16px;
    }

    // FieldArray 在沒有資料時顯示的提示文字，不需要在畫面上
    .MuiGrid-item:has(p) {
      display: none;
    }
  }

  // 移除維修人員 2 電話欄位下的 margin
  .DynamicArray-fieldArrayGroup {
    margin-bottom: 0 !important;
  }

  h5 {
    color: var(--ion-color-primary);
  }

  p.Mui-required,
  label.Mui-required {
    color: var(--ion-color-primary);
    font-weight: bold;
  }

  label.Mui-required .MuiFormLabel-asterisk {
    color: var(--ion-color-danger) !important;
  }

  label.Mui-required ~ .MuiFormGroup-root checkbox {
    color: var(--ion-color-danger) !important;
  }

  ion-item-divider.divider {
    border: none;
    background: transparent;
  }

  ion-item-divider.field {
    border: none;
    background: transparent;

    font-size: 16px;
  }

  ion-toolbar.edit-button {
    --min-height: auto;
  }

  div.form-error-message {
    padding: 5px 0 15px 0;
    background: var(--ion-color-light);

    ion-list {
      border: 2px dashed var(--ion-color-danger);
    }
  }

  .MuiAutocomplete-inputRoot input[readonly] {
    -webkit-touch-callout: none !important;
    user-select: none !important;
    cursor: pointer !important;
  }

  .form-disabled > * {
    pointer-events: none;
    cursor: not-allowed !important;
  }

  .form-disabled.allow-select-options div[role='combobox'] {
    pointer-events: auto !important;
  }

  .form-disabled {
    cursor: not-allowed !important;
  }

  div.tips {
    color: var(--ion-color-danger);
    font-weight: bold;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: right;
  }

  ion-item.date-picker {
    overflow: visible;
    border: 2px solid var(--ion-color-warning);
    margin-bottom: 5px;

    ion-buttons.action {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      ion-button {
        border: 2px solid transparent;
        font-size: 16px;
        width: 100%;
      }
    }

    ion-label > h2 {
      color: var(--ion-color-primary);
      font-weight: bold;
      font-size: 12px;
    }

    .react-datepicker-wrapper {
      margin-top: 1px;
      display: inline-block;
    }

    .react-datepicker__close-icon::after {
      background-color: rgba(var(--ion-color-medium-rgb), 0.5);
    }
  }

  ion-list-header.advanced,
  ion-list-header.files {
    margin-bottom: 0;
    padding-right: 4px;
    h3 {
      font-weight: bold;
    }
  }

  ion-list.fix-items {
    ion-item.fix-item {
      font-size: 13px;
      font-weight: bold;
      color: dashed var(--ion-color-medium);
      &:hover {
        --color: rgba(var(--ion-color-secondary-rgb), 1);
        --background: rgba(var(--ion-color-warning-rgb), 0.5);
      }
    }

    ion-button.download {
      font-size: 13px;
    }

    ion-note {
      margin: 0;
    }

    ion-thumbnail {
      width: 48px;
      height: 48px;
    }

    ion-item-divider.download {
      margin-bottom: 5px;
    }

    ion-card {
      display: inline-flex;
      padding: 5px !important;
      margin: 0 !important;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      &:hover {
        background: rgba(var(--ion-color-warning-rgb), 0.5);
      }

      ion-thumbnail {
        padding: 0 !important;
        margin: 0 !important;

        img {
          display: block;
          align-items: center;
          justify-content: center;
        }
      }

      ion-progress-bar {
        position: absolute;
        width: calc(100% - 10px);
        bottom: 1px;
      }
    }
  }

  ion-item-divider.action {
    margin: 0;
    padding: 4px;

    ion-button.count {
      --padding-start: 2px;
      --padding-end: 2px;
    }

    // 移除 ion-item-divider 底部的 border
    border-bottom: none;
  }
}
