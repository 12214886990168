.qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 20px;
}

.url {
  padding: 10px 30px;
}