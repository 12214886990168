.react-tooltip-lite {
  background: #333;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}
