.photo-details-card {
  display: block;
  align-items: center;

  .photo-info {
    flex: 1;
    height: 100%;
    padding-left: 20px;
  }

  .photo-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .photo-name {
    color: var(--ion-color-medium);
    word-break: break-all;
  }

  .photo-memo {
    margin: 0;
    margin-bottom: 16px;
    white-space: initial;
  }
}
