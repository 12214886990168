.page-login {
  @import '../../theme/logo';

  ion-content.bg {
    --background: var(--ion-color-light);
  }

  ion-grid {
    max-width: 430px;
    min-height: calc(100vh - 125px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  form {
    width: 100%;
  }

  ion-list.form-container {
    border-radius: 10px;
    padding: 10px 15px;

    .login-btn {
      font-size: 16px;
      height: 46px;
    }
  }
}
