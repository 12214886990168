.page-permission-modal {
  ion-content{
    --background: var(--ion-color-light);
  }
  .all-member-tooltip {
    position: relative;
    top: 3px;
    z-index: 9999;

    ion-icon {
      font-size: 24px;
    }
  }

  ion-list.bg {
    background: var(--ion-color-light);

    ion-radio-group {
      ion-item {
        --background: var(--ion-color-light);
      }
    }
  }

  ion-list-header {
    margin-bottom: 0;
  }
}
