.component-toggle-comment-fab {
  ion-fab-button {
    position: relative;

    .has-comment-dot {
      position: absolute;
      color: var(--ion-color-danger);
      font-size: 3rem;
      top: -15px;
      right: 5px;

      &.green {
        color: var(--ion-color-success);
      }

      // Notice: 目前不明原因，Android 版本的小紅點起始位置與其他裝置不一致，因此特別為這個條件設定了特殊位置
      &.is-android {
        top: -20px;
        right: -8px;
      }
    }
  }
}
