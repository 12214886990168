.page-list-ticket-comments {
  ion-content.bg {
    --background: var(--ion-color-light);
  }

  ion-list {
    margin: 0;
    padding: 1px 0 1px 0;
  }

  ion-card {
    box-shadow: none !important;
    border: 1px solid rgba(var(--ion-color-medium-rgb), 0.15);

    ion-card-content {
      padding: 0 5px;
      display: block;
    }
  }

  ion-item-divider {
    border: none;
    padding: 0;
  }

  ion-badge.file-count {
    height: auto;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 24px;
    font-family: monospace;
    font-weight: bold;
    margin-left: 5px;
  }

  ion-badge.comment-count {
    margin-right: 8px;
    padding: 3px 5px 4px 5px;
  }

  .new-comment-actions {
    ion-buttons {
      margin-inline-start: 0;
      margin-inline-end: 0;

      // 避免用戶名稱過長時與其他按鈕重疊
      ion-button[data-title=username] span {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
      }
    }
  }

  .pb-5 {
    padding-bottom: 5px;
  }

  .user {
    font-weight: 700;
  }
}
