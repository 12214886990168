.page-list-ticket {
  @import '../../theme/logo';

  ion-content.bg {
    --background: var(--ion-color-light);
  }

  ion-list {
    margin: 0;
    padding: 1px 0 1px 0;
    background: transparent;
  }

  ion-card {
    box-shadow: none !important;
    border: none !important;

    ion-card-content {
      padding: 0 5px;
      display: block;
    }
  }

  ion-item {
    .serial {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      min-width: 14px; // 沒有工單序號時留白
      margin-inline-end: 8px;
    }

    ion-label {
      padding-left: 8px;
    }

    &.over-expected-end-date {
      --background: rgba(var(--ion-color-danger-rgb), 0.14) !important;
    }
  }

  ion-searchbar {
    height: 56px;
  }

  .date-info {
    margin: 0 !important;
    padding: 0 !important;
    padding-top: 5px !important;
    font-size: 12px;
    font-weight: 700;
    ion-badge {
      font-size: 12px !important;
    }
  }

  ion-text {
    padding-right: 5px;
  }

  .ticket-status {
    padding: 7px 11px;
    margin-right: 8px;
    border-radius: 0.8rem;
  }

  .ticket-status-done {
    background-color: rgba(var(--ion-color-success-rgb), 0.2);
    color: var(--ion-color-success);
  }

  .ticket-status-in-progress {
    background-color: rgba(var(--ion-color-secondary-rgb), 0.2);
    color: var(--ion-color-secondary);
  }

  .ticket-status-postponed {
    background-color: rgba(var(--ion-color-danger-rgb), 0.2);
    color: var(--ion-color-danger);
  }

  .ticket-status-observing {
    background-color: rgba(var(--ion-color-warning-rgb), 0.2);
    color: var(--ion-color-warning-dark);
  }

  .ticket-status-preparing {
    background-color: rgba(#623DB1, 0.2);
    color: #623DB1; 
  }

  .ticket-status-waiting {
    background-color: rgba(#5AA1EE, 0.2);
    color: #5AA1EE;
  }

  .observation-start-date {
    color: var(--ion-color-warning-dark);
  }
}
